import React, {useState, useEffect } from 'react'
import './Feedback.css'
import Carousel from '../components/Carousel'

function Feedback() {
    const [show, setShow] = useState(3);

    const handleResize = () => {
        if (window.innerWidth < 700) {
            setShow(1);
        } else if(window.innerWidth < 1000) {
            setShow(2);
        } else {
            setShow(3);
        }
    };

    useEffect(() => {
        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <>
        <div className='feedbackContainer'>
            <Carousel className='feedbackCarousel' show={show}>
                <div className='feedback'>
                    <h3 className='name'>Arleen G.</h3>
                    <h4 className='ref'>FruitSharer</h4>
                    <h4 className='longcontent'>What a great team and service!! Arrived on time with all the needed equipment. Were super respectful and polite, were quick, and appreciative. My trees had a lot of fruit, So I’m very happy this is going to a good cause. I never could’ve done this myself. Thank you so much for offering this wonderful service!!! I highly recommend!!!</h4>
                </div>
                <div className='feedback'>
                    <h3 className='name'>Stephanie J.</h3>
                    <h4 className='ref'>FruitSharer</h4>
                    <h4 className='longcontent'>I'm so happy to have found out about this organization through Nextdoor. They did an excellent job picking my abundnace of persimmons from my tree for donation. Will look forward to having them come again next year. They were prompt and very kind. Great organization-highly recommend!</h4>
                </div>
                <div className='feedback'>
                    <h3 className='name'>Cass K.</h3>
                    <h4 className='ref'>FruitSharer</h4>
                    <h4 className='longcontent'>Excellent communication and clarity. Clarified which trees needed to be picked, whether any fruit was to be retained for the homeowner, and they picked carefully and cleaned up afterwards.</h4>
                </div>
                <div className='feedback'>
                    <h3 className='name'>Edith T.</h3>
                    <h4 className='ref'>FruitSharer</h4>
                    <h4 className='content'>The staff arrived promptly, picked the fruit with impressive efficiency, and even took care to clean up most of the fallen fruit.</h4>
                </div>
                <div className='feedback'>
                    <h3 className='name'>Laura D.</h3>
                    <h4 className='ref'>FruitSharer</h4>
                    <h4 className='content'>These guys are so awesome. They came by and picked all the plums even cleaned up the ones that were on the ground! I am so glad to have something like this in the neighborhood to do good for others </h4>
                </div>
                <div className='feedback'>
                    <h3 className='name'>MaryAnn W.</h3>
                    <h4 className='ref'>Nextdoor.com</h4>
                    <h4 className='content'>Please allow me to Compliment your Wonderful, Thoughtful, Caring, Compassionate idea to reach out to our Communities to help them!</h4>
                </div>

                <div className='feedback'>
                    <h3 className='name'>Sharon S.</h3>
                    <h4 className='ref'>Nextdoor.com</h4>
                    <h4 className='content'>That is a great service that you are providing!  Many people benefit!</h4>
                </div>
                
                <div className='feedback'>
                    <h3 className='name'>Mary F.</h3>
                    <h4 className='ref'>Nextdoor.com</h4>
                    <h4 className='content'>Thank you for doing this. Something I’ve always thought about doing. So many homeowners just ignore their fruit trees.</h4>
                </div>

                <div className='feedback'>
                    <h3 className='name'>Denise H.</h3>
                    <h4 className='ref'>Nextdoor.com</h4>
                    <h4 className='content'>Thank you for doing this</h4>
                </div>

                <div className='feedback'>
                    <h3 className='name'>Nima K.</h3>
                    <h4 className='ref'>Nextdoor.com</h4>
                    <h4 className='content'>What an amazing idea you kids have come up with🙏</h4>
                </div>
                
            </Carousel>
        </div>
    </>
  )
}

export default Feedback